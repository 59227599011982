import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes.js";
import axios from "axios";

function* fetchTakedownData() {
    try {
        const response = yield call(axios.get, "https://api-agent.goloti.com/artists/takedown-completion-stats");
        yield put({ type: actionTypes.GET_TAKEDOWN_DATA_SUCCESS, payload: response.data.data}); 
    } catch (error) {
        yield put({ type: actionTypes.GET_TAKEDOWN_DATA_FAILURE, error: error.message });
    }
};

export default function* takedownSaga (){
    yield all ([
        takeLatest(actionTypes.GET_TAKEDOWN_DATA , fetchTakedownData),
    ])
};