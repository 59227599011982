import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch"; // Import the Switch component
import useProcessorStore from "../processorStore";
import { useStore } from "zustand";
import { addProcessor } from "@/redux/Processor/actions";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";

const ProcessorForm = ({  }) => {
    const dispatch = useDispatch();
    const { processorData } = useStore(useProcessorStore);
    const [formData, setFormData] = useState({
        id: "",
        is_active: false,
        queue_threshold: "",
        type: "",
        source: "",
        proxy: "",
        download_batch_size: "",
    });
    const [selectedSource, setSelectedSource] = useState(formData.source || "Select source");
    const [selectedType, setSelectedType] = useState(formData.type || "Select type");


    // Extract unique sources and remove 'source-' prefix
    const uniqueSources = useMemo(() => {
        const sources = processorData.map((proc) => proc.source);
        const unique = Array.from(new Set(sources));
        return unique.map((src) => src.replace(/^source-/, ""));
    }, [processorData]);

    const uniqueTypes = useMemo(() => {
        const types = processorData.map((item) => item.type); // Extract 'type' from data
        return [...new Set(types)]; // Remove duplicates
    }, [processorData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleToggle = () => {
        setFormData((prev) => ({
            ...prev,
            is_active: !prev.is_active, // Toggle the is_active state
        }));
    };

    const handleSave = () => {
        // Dispatch the Redux action to add the processor
        dispatch(addProcessor(formData));
        setFormData({
            id: "",
            is_active: false,
            queue_threshold: "",
            type: "image",
            source: "",
            proxy: "",
            download_batch_size: "",
        });
    };

    const handleSelectSource = (source) => {
        setSelectedSource(source);
        setFormData((prevData) => ({
            ...prevData,
            source,
        }));
    };

    const handleSelectType = (type) => {
        setSelectedType(type);
        setFormData((prevData) => ({
            ...prevData,
            type,
        }));
    };
    return (
        <div className="p-4 border rounded-md bg-gray-100">
            <div className="flex flex-wrap gap-8">
                <div className="flex flex-col">
                    <label htmlFor="id" className="font-semibold text-lg">
                        ID
                    </label>
                    <input
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={handleChange}
                        className="border rounded px-2 py-1"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="queue_threshold" className="font-semibold text-lg">
                        Queue Threshold
                    </label>
                    <input
                        type="number"
                        name="queue_threshold"
                        value={formData.queue_threshold}
                        onChange={handleChange}
                        className="border rounded px-2 py-1"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="type" className="font-semibold text-lg">
                        Type
                    </label>
                    <DropdownMenu>
                        <DropdownMenuTrigger className="border rounded px-2 py-1 w-48 text-left">
                            {selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {uniqueTypes.map((type, idx) => (
                                <DropdownMenuItem
                                    key={idx}
                                    onClick={() => handleSelectType(type)}
                                >
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="source" className="font-semibold text-lg">
                        Source
                    </label>
                    <DropdownMenu>
                        <DropdownMenuTrigger className="border rounded px-2 py-1 w-48 text-left">
                            {selectedSource.charAt(0).toUpperCase() + selectedSource.slice(1)}
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {uniqueSources.map((source, idx) => (
                                <DropdownMenuItem
                                    key={idx}
                                    onClick={() => handleSelectSource(`source-${source}`)}
                                >
                                    {source.charAt(0).toUpperCase() + source.slice(1)}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="proxy" className="font-semibold text-lg">
                        Proxy
                    </label>
                    <input
                        type="text"
                        name="proxy"
                        value={formData.proxy}
                        onChange={handleChange}
                        className="border rounded px-2 py-1"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="download_batch_size" className="font-semibold text-lg">
                        Batch Size
                    </label>
                    <input
                        type="number"
                        name="download_batch_size"
                        value={formData.download_batch_size}
                        onChange={handleChange}
                        className="border rounded px-2 py-1"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="is_active" className="font-semibold text-lg">
                        Active Status
                    </label>
                    <Switch
                        checked={formData.is_active}
                        onClick={handleToggle} // Use the toggle handler
                        className="mt-2"
                    />
                </div>
            </div>
            <div className="flex gap-4 mt-4 justify-end">
                <Button onClick={handleSave} className="bg-green-700 text-white">
                    Save
                </Button>
            </div>
        </div>
    );
};

export default ProcessorForm;
