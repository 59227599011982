import usePostProcessingStore from "@/pages/PostProcessing/postProcessingStore.js";
import * as actionTypes from "./actionTypes.js";

const initialState = {
    loading: false,
    data: {},
    error: null,
}

const postProcessingReducer = (state = initialState, action) => {
    const postProcessingStore = usePostProcessingStore.getState();

    switch (action.type) {
        case actionTypes.GET_POST_PROCESSING_DATA:
            postProcessingStore.setLoading(true)
            return { ...state, loading: false, error: null }

        case actionTypes.GET_POST_PROCESSING_DATA_SUCCESS:
            postProcessingStore.setPostProcessingData(action.payload)
            postProcessingStore.setLoading(false)
            return { ...state, loading: false, data: action.payload, error: null }

        case actionTypes.GET_POST_PROCESSING_DATA_FAILURE:
            postProcessingStore.setLoading(false)
            return { ...state, loading: false, error: action.error }

        default:
            return state;
    }
}

export default postProcessingReducer;
