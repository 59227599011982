import {format, subDays} from "date-fns";
import {create} from "zustand";

const useComaprisionStore = create((set) => ({
    // Artists
    artists: [],
    setArtists: (artists) => set({artists}),

    isArtistsLoading: false,
    setIsArtistsLoading: (isArtistsLoading) => set({isArtistsLoading}),

    // Form
    formData: {
        teamA: [],
        teamB: [],
        dates: {
            from: format(subDays(new Date(), 30), "yyyy-MM-dd"),
            to: format(new Date(), "yyyy-MM-dd"),
        },
    },
    setFormData: (formData) => set({formData}),

    isFormLoading: false,
    setIsFormLoading: (isFormLoading) => set({isFormLoading}),

    searchResults: {
        teamA: null,
        teamB: null,
    },
    setSearchResults: (searchResults) => set({searchResults}),

    // Notes
    notes: [],
    setNotes: (notes) => set({notes}),

    // Page Settings
    comparisionView: false,
    setComparisionView: (comparisionView) => set({comparisionView}),

    detailedView: true,
    setDetailedView: (detailedView) => set({detailedView}),

    error: null,
    setError: (error) => set({error}),
}));

export default useComaprisionStore;
