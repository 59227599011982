"use client";
import * as React from "react";
import { format, isSameDay } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { DateRangePicker } from "react-date-range";
import { DateTime } from "luxon";

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    },
};

function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

const staticRanges = createStaticRanges([
    {
        label: "Today",
        range: () => ({
            startDate: DateTime.local().endOf("day").toJSDate(),
            endDate: DateTime.local().endOf("day").toJSDate(),
        })
    },
    {
        label: "Yesterday",
        range: () => ({
            startDate: DateTime.local().minus({ days: 1 }).startOf("day").toJSDate(),
            endDate: DateTime.local().minus({ days: 1 }).endOf("day").toJSDate(),
        })
    },
    {
        label: "Last 7 days",
        range: () => ({
            startDate: DateTime.local().minus({ days: 7 }).startOf("day").toJSDate(),
            endDate: DateTime.local().endOf("day").toJSDate(),
        })
    },
    {
        label: "Last 15 days",
        range: () => ({
            startDate: DateTime.local().minus({ days: 15 }).startOf("day").toJSDate(),
            endDate: DateTime.local().endOf("day").toJSDate(),
        })
    },
    {
        label: "Last 30 days",
        range: () => ({
            startDate: DateTime.local().minus({ days: 30 }).startOf("day").toJSDate(),
            endDate: DateTime.local().endOf("day").toJSDate(),
        })
    },
    {
        label: "Last month",
        range: () => ({
            startDate: DateTime.local().minus({ months: 1 }).startOf("month").toJSDate(),
            endDate: DateTime.local().minus({ months: 1 }).endOf("month").toJSDate(),
        })
    },
]);

export function DatePickerWithRange({ className, selectedDates, onChange }) {
    const formatDate = (date) => (date ? format(date, "yyyy-MM-dd") : "");

    const onDateSaveFormat = ({ selection }) => {
        onChange({
            from: formatDate(selection?.startDate),
            to: formatDate(selection?.endDate),
        });
    };

    return (
        <div className={cn("grid", className)}>
            <Popover>
                <label className="text-sm mb-1 text-gray-600 font-normal">
                    Date Range
                </label>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant="outline"
                        className={cn(
                            "w-[300px] justify-start text-left font-normal h-10",
                            !selectedDates && "text-muted-foreground"
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {selectedDates?.from ? (
                            selectedDates.to ? (
                                <>
                                    {format(selectedDates.from, "d MMM yyyy")} -{" "}
                                    {format(selectedDates.to, "d MMM yyyy")}
                                </>
                            ) : (
                                formatDate(selectedDates.from)
                            )
                        ) : (
                            <span>Pick date range</span>
                        )}
                    </Button>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0">
                    <DateRangePicker
                        ranges={[
                            {
                                startDate: new Date(selectedDates?.from),
                                endDate: new Date(selectedDates?.to),
                                key: "selection",
                            },
                        ]}
                        onChange={onDateSaveFormat}
                        maxDate={new Date()}
                        staticRanges={staticRanges}
                        months={2}
                        direction="horizontal"
                        showMonthAndYearPickers={true}
                        showDateDisplay={false}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}