import {
  GET_ARTISTS_DATA,
  GET_ARTISTS_DATA_SUCCESS,
  GET_ARTISTS_DATA_FAILURE,
} from "./actionTypes";
import useComaprisionStore from "@/pages/UserComparison/userComparisionStore";

const initialState = {
  comparisionView: true,
  artists: [],
  isArtistsLoading: false,
  form: {},
  formLoading: false,
};

const userComparisionReducer = (state = initialState, action) => {
  const comparisionStore = useComaprisionStore.getState();

  switch (action.type) {
    case GET_ARTISTS_DATA:
      comparisionStore.setIsArtistsLoading(true);
      return { ...state, isArtistsLoading: true, error: null };

    case GET_ARTISTS_DATA_SUCCESS:
      comparisionStore.setArtists(action.payload);
      comparisionStore.setIsArtistsLoading(false);
      return {
        ...state,
        isArtistsLoading: false,
        artists: action.payload,
        error: null,
      };

    case GET_ARTISTS_DATA_FAILURE:
      comparisionStore.setError(action.error);
      return { ...state, isArtistsLoading: false, error: action.error };

    default:
      return state;
  }
};

export default userComparisionReducer;
