import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes.js";
import API from "@/axios/axios.js";

function* fetchPostProcessingData() {
    try {
        const response = yield call(API.get, "/post-processing/stats?days=15");
        yield put({ type: actionTypes.GET_POST_PROCESSING_DATA_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: actionTypes.GET_POST_PROCESSING_DATA_FAILURE, error: error.message });
    }
};

export default function* postProcessingSaga() {
    yield all([
        takeLatest(actionTypes.GET_POST_PROCESSING_DATA, fetchPostProcessingData),
    ])
};