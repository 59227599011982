import { useState, useEffect } from "react";

const rotatingTexts = [
  "~ Loti finds 20K deepfake records per day",
  "~ Loti scans 50K social media accounts daily",
  "~ Loti monitors 10K merchandise websites hourly",
];

export default function Loader() {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex(
        (prevIndex) => (prevIndex + 1) % rotatingTexts.length
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full h-full py-12 flex flex-col gap-6 items-center justify-center text-center">
      <p className="text-2xl">Generating reports, please wait...</p>

      <div className="flex flex-col gap-2">
        <p className="text-gray-400 text-sm">Did you know?</p>

        <div className="relative h-6 w-96">
          <p
            key={currentTextIndex}
            className="absolute inset-0 text-gray-600 text-base transform animate-rotate-3d w-full text-center"
          >
            {rotatingTexts[currentTextIndex]}
          </p>
        </div>
      </div>
    </div>
  );
}
