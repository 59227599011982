import {useState, useMemo, useEffect} from "react";
import {ChevronDown} from "lucide-react";
import {Checkbox} from "./checkbox";
import {
    DropdownMenu, DropdownMenuTrigger, DropdownMenuContent,
} from "./dropdown-menu";
import ButtonWrapper from "@/components/common/Wrappers/Button/ButtonWrapper.jsx";

export default function MultiSelect({
                                        id = null,
                                        data = [],
                                        selectedValues = [],
                                        onChange,
                                        onSelectAllChange,
                                        label = "Select",
                                    }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [allSelected, setAllSelected] = useState(false);
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        const queryData = data.filter((artist) => artist.full_name.toLowerCase().includes(searchQuery.toLowerCase()));
        setFilteredData([
            ...queryData.filter((artist) => selectedValues.includes(artist.user_id)),
            ...queryData.filter((artist) => !selectedValues.includes(artist.user_id)),
        ])
    }, [searchQuery, data]);

    useEffect(() => {
        const allSelected = filteredData.length > 0 && filteredData.every((artist) => selectedValues.includes(artist.user_id));
        setAllSelected(allSelected);
    }, [filteredData, selectedValues]);

    const onDropdownClose = () => {
        setSearchQuery("");
    }

    const onDropdownOpen = () => {
        const sortedData = [
            ...filteredData.filter((artist) => selectedValues.includes(artist.user_id)),
            ...filteredData.filter((artist) => !selectedValues.includes(artist.user_id)),
        ]
        setFilteredData(sortedData);
    }

    const resetSelection = () => {
        onSelectAllChange(id, data, false);
    }

    const selectedText = useMemo(() => {
        if (!selectedValues.length) return "Select Artists";

        return (<div className="flex items-center gap-1">
            {selectedValues?.slice(0, 5)?.map((selected, i) => (<img
                key={selected}
                src={data.find((item) => item.user_id === selected)?.asset_url}
                className={`w-6 h-6 rounded-full border-2 border-border-input bg-gray-600 ${i !== 0 && "ml-[-15px]"}`}
            />))}

            {selectedValues?.length <= 5 ? (<span className="text-sm font-light text-gray-600">
            {selectedValues.length} selected
          </span>) : (<span className="text-sm font-light text-gray-600">
            + {selectedValues.slice(5, selectedValues?.length).length} selected
          </span>)}
        </div>);
    }, [selectedValues, data]);

    return (<DropdownMenu
        onOpenChange={(open) => {
            if (!open) onDropdownClose();
            else onDropdownOpen();
        }}
    >
        <div className="flex flex-col">
            {label && <label className="text-sm mb-1">{label}</label>}

            <DropdownMenuTrigger asChild>
                <div
                    className="border border-border-input pl-3 pr-2 py-2 rounded-md w-64 flex justify-between items-center h-10 cursor-pointer">
            <span className="w-52 whitespace-nowrap overflow-hidden text-ellipsis">
              {selectedText}
            </span>
                    <ChevronDown className="w-4 h-4"/>
                </div>
            </DropdownMenuTrigger>
        </div>

        <DropdownMenuContent className="h-fit relative">
            <div className="bg-white w-full pt-2 pb-1 sticky top-[-5px] px-2">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="border border-gray-300 rounded-md px-4 py-1 mb-2 font-light text-base w-full"
                />
            </div>

            <div className="flex flex-col h-80 gap-1 px-2 py-1 w-60 overflow-y-auto">

                {filteredData.length < 50 && (
                    <div
                        className="flex gap-4 items-center cursor-pointer py-1"
                    >
                        <Checkbox
                            id={`checkbox-all`}
                            checked={allSelected}
                            onCheckedChange={(value) => onSelectAllChange(id, filteredData, value)}
                        />
                        <label
                            htmlFor={`checkbox-all`}
                            className="flex items-center gap-2 cursor-pointer"
                        >
                            <span className="font-normal line-clamp-1">
                    Select All
                  </span>
                        </label>
                    </div>
                )}

                {filteredData.length > 0 ? (filteredData.map((artist) => (<div
                    className="flex gap-4 items-center cursor-pointer py-1"
                    key={artist.user_id}
                >
                    <Checkbox
                        id={`checkbox-${artist.user_id}`}
                        checked={selectedValues.includes(artist.user_id)}
                        onCheckedChange={(value) => onChange(id, artist.user_id, value)}
                    />
                    <label
                        htmlFor={`checkbox-${artist.user_id}`}
                        className="flex items-center gap-2 cursor-pointer"
                    >
                        <img
                            src={artist.asset_url}
                            className="w-6 h-6 rounded-full bg-gray-600"
                            alt={"asset-url"}/>
                        <span className="font-normal line-clamp-1">
                    {artist.full_name}
                  </span>
                    </label>
                </div>))) : (<p className="text-gray-500">No data found</p>)}
            </div>

            <div className={"w-full p-2 flex flex-row justify-end"}>
                <ButtonWrapper
                    className={"h-8 text-[--destructive]"}
                    text={"Reset"}
                    variant={"ghost"}
                    onClick={resetSelection}
                />
            </div>
        </DropdownMenuContent>
    </DropdownMenu>);
}
