import TakedownStatSection from "@/pages/TakedownStats/components/TakedownStatSection.jsx";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useTakedownStatsStore from "./takedownStatsStore";
import { getTakedownData } from "@/redux/TakedownStats/actions";

const TakedownStats = () => {
    const dispatch = useDispatch();
    const takedownData = useTakedownStatsStore(state => state.takedownData)

    useEffect(() => {
        dispatch(getTakedownData());
    }, [dispatch]);

    return (
        <div className={"flex flex-col gap-5 w-full h-full"}>
            <TakedownStatSection
                title="Media Takedowns"
                type="media"
                stats={takedownData.media}
            />
            <TakedownStatSection
                title="Account Takedowns"
                type="account"
                stats={takedownData.account}
            />
        </div>
    );
}

export default TakedownStats;