import TakedownStatCard from "@/pages/TakedownStats/components/TakedownStatCard.jsx";
import useTakedownStatsStore from "../takedownStatsStore";
import { Skeleton } from "@/components/ui/skeleton";

const TakedownStatSection = ({title, type, stats}) => {
    const loading = useTakedownStatsStore((state) => state.loading);
    const safeStats = stats || {};

    return (
        <div className="mb-8">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">{title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {loading
                    ? Object.keys(safeStats).map((_, index) => (
                        <Skeleton
                            key={index}
                            className="w-full h-32 rounded-md"
                        />
                    ))
                    : Object.entries(safeStats).map(([platform, data]) => (
                        <TakedownStatCard
                            key={platform}
                            platform={platform}
                            completed={data.takedown_completed}
                            total={data.takedown_count}
                            percentage={data.percentage}
                            type={type}
                        />
                    ))}
            </div>
        </div>
    );
}

export default TakedownStatSection;