import dashboardSaga from "@/redux/Dashboard/saga.js";
import { all } from "redux-saga/effects";
import processorSaga from "./Processor/saga";
import takedownSaga from "./TakedownStats/saga";
import userComparisionSaga from "./UserComparision/saga";
import postProcessingSaga from "./PostProcessing/saga";

export default function* rootSaga() {
  yield all([
    dashboardSaga(),
    processorSaga(),
    takedownSaga(),
    userComparisionSaga(),
    postProcessingSaga(),
  ]);
}
