import useTakedownStatsStore from "@/pages/TakedownStats/takedownStatsStore.js";
import * as actionTypes from "./actionTypes.js";

const initialState = {
    loading: false,
    data: {},
    error: null,
}

const takedownReducer = (state = initialState , action) => {
    const takedownStatsStore = useTakedownStatsStore.getState();

    switch (action.type) {
        case actionTypes.GET_TAKEDOWN_DATA:
            takedownStatsStore.setLoading(true)
            return {...state , loading: false , error: null}
        
        case actionTypes.GET_TAKEDOWN_DATA_SUCCESS:
            takedownStatsStore.setTakedownData(action.payload)
            takedownStatsStore.setLoading(false)
            return {...state , loading: false , data: action.payload , error: null}

        case actionTypes.GET_TAKEDOWN_DATA_FAILURE:
            takedownStatsStore.setLoading(false)
            return {...state , loading: false , error: action.error}

        default:
            return state;
    }
}

export default takedownReducer;
