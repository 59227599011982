import {useStore} from "zustand";
import useComaprisionStore from "./userComparisionStore";
import {useCallback, useMemo} from "react";
import {formatNumber} from "../Dashboard/utils";
import {CircularProgress} from "@/components/ui/circular-progress";
import InstagramIcon from "@/assets/icons/InstagramIcon";
import {Button} from "@/components/ui/button";
import {Plus, X} from "lucide-react";
import AdultIcon from "@/assets/icons/AdultIcon";
import TikTokIcon from "@/assets/icons/TikTokIcon";
import RedditIcon from "@/assets/icons/RedditIcon";
import PinterestIcon from "@/assets/icons/PinterestIcon";
import MetaIcon from "@/assets/icons/MetaIcon";
import LiveIcon from "@/assets/icons/LiveIcon";
import EbayIcon from "@/assets/icons/EbayIcon";
import YoutubeIcon from "@/assets/icons/YoutubeIcon";

const tableHeading = {
    total: "Total Results",
    impersonators: "Impersonators",
    deepfakes: "Deep Fakes",
    adult: "Adult Content",
    social: "Social",
    merchandise: "Merchandise",
};

const socialMediaIcons = {
    instagram: {
        icon: <InstagramIcon height={18} width={18}/>,
        color: "#FF9749",
    },
    facebook: {icon: <MetaIcon height={18} width={18}/>, color: "#1877F2"},
    youtube: {icon: <YoutubeIcon height={22} width={22}/>, color: "#FF0000"},
    pinterest: {
        icon: <PinterestIcon height={18} width={18}/>,
        color: "#CB1F27",
    },
    reddit: {icon: <RedditIcon height={18} width={18}/>, color: "#FF9749"},
    tiktok: {icon: <TikTokIcon height={26} width={26}/>, color: "#FF004F"},
    adult: {icon: <AdultIcon height={22} width={22}/>, color: "#C21010"},
    ebay: {icon: <EbayIcon height={24} width={24}/>, color: "#918604"},
};

export const useTable = () => {
    const {comparisionView, detailedView, searchResults, notes, setNotes} =
        useStore(useComaprisionStore);

    const addNote = (parentId) => {
        setNotes([
            ...notes,
            {
                id: Math.random().toString(36).substr(2, 9),
                parentId,
            },
        ]);
    };

    const deleteNote = (id) => {
        setNotes([...notes.filter((note) => note.id !== id)]);
    };

    const renderCircularProgress = (total, value, icon, color) => {
        if (!value || value === 0) return null;

        return (
            <div
                className="w-12 flex flex-col items-center text-center gap-2"
                key={`category-children-${value}-${color}`}
            >
                <CircularProgress
                    max={total}
                    min={0}
                    value={value}
                    gaugePrimaryColor={color}
                    gaugeSecondaryColor="#EDEDED"
                    icon={icon}
                />
                <span className="text-xs text-gray-600">{formatNumber(value)}</span>
            </div>
        );
    };

    const renderCategoryData = useCallback(
        (category, data) => {
            const discover = data?.comparison_stats?.discover;
            const {box} = discover || {};
            if (!discover) return;

            const renderCategoryProgress = (total, categoryBox) => {
                return Object.entries(categoryBox).map(([key, value]) => {
                    const {icon, color} = socialMediaIcons?.[key] || {
                        icon: <LiveIcon width={20} height={20}/>,
                        color: "#000000",
                    };

                    return renderCircularProgress(
                        total,
                        value.total,
                        icon,
                        color
                    );
                });
            };

            const categoryMap = {
                total: {
                    value: discover?.total,
                    progressData: {
                        ...box?.media?.box?.social?.box,
                        adult: box?.media?.box?.adult,
                        common: box?.media?.box?.common,
                    },
                },
                deepfakes: {
                    value: box?.media?.categories?.deepfakes?.total,
                    progressData: {
                        ...box?.media?.categories?.deepfakes?.box?.social?.box,
                        adult: box?.media?.categories?.deepfakes?.box?.adult,
                        common: box?.media?.categories?.deepfakes?.box?.common,
                    },
                },
                impersonators: {
                    value: box?.impersonators?.total,
                    progressData: {
                        ...box?.impersonators?.box,
                    },
                },
                adult: {
                    value: box?.media?.box?.adult?.total,
                    progressData: {},
                },
                social: {
                    value: box?.media?.box?.social?.total,
                    progressData: {
                        ...Object.fromEntries(
                            Object.entries(box?.media?.box?.social?.box || {}).filter(([key]) => key !== 'ebay')
                        ),
                    },
                },
                merchandise: {
                    value: box?.media?.categories?.merchandise?.total,
                    progressData: {
                        ...box?.media?.categories?.merchandise?.box?.social?.box,
                        adult: box?.media?.categories?.merchandise?.box?.adult,
                    },
                },
            };

            const categoryData = categoryMap[category];

            if (!categoryData) return null;

            return (
                <>
                    <h4 className="text-xl font-semibold text-black">
                        {formatNumber(categoryData.value)}
                    </h4>

                    {detailedView && categoryData.progressData && (
                        <div className="flex gap-2 items-center justify-start mt-3">
                            {renderCategoryProgress(categoryData.value, categoryData.progressData)}
                        </div>
                    )}
                </>
            );
        },
        [detailedView]
    );

    const tableRows = useMemo(() => {
        return Object.keys(tableHeading).map((category, i) => (
            <tr
                className={`border border-border-input ${i % 2 !== 0 && "bg-gray-50"}`}
                key={category}
            >
                <td className="p-4 font-normal text-sm text-gray-600">
                    {tableHeading[category]}
                </td>
                <td className="p-4 font-normal text-md text-gray-600 border-l border-border-input">
                    {renderCategoryData(category, searchResults.teamA)}
                </td>
                {comparisionView && (
                    <td className="border-l border-border-input p-4">
                        {renderCategoryData(category, searchResults.teamB)}
                    </td>
                )}
                {/*<td className="border-l border-border-input p-4  w-[600px]">*/}
                {/*    {notes*/}
                {/*        .filter((note) => note.parentId === category)*/}
                {/*        .map((item) => (*/}
                {/*            <div key={item.id} className="flex items-center gap-2 group">*/}
                {/*                <input*/}
                {/*                    className="border border-transparent bg-transparent group-hover:bg-white group-hover:border group-hover:border-gray-300 w-full px-4 py-2 rounded-sm mb-2"/>*/}
                {/*                <X*/}
                {/*                    className="h-6 w-6 text-red-600 cursor-pointer opacity-0 group-hover:opacity-100"*/}
                {/*                    onClick={() => deleteNote(item.id)}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    <Button*/}
                {/*        className="text-blue-600"*/}
                {/*        variant="link"*/}
                {/*        onClick={() => addNote(category)}*/}
                {/*    >*/}
                {/*        <Plus className="h-4 w-4"/>*/}
                {/*        Add Temporary Notes*/}
                {/*    </Button>*/}
                {/*</td>*/}
            </tr>
        ));
    }, [tableHeading, searchResults, notes, comparisionView, renderCategoryData]);

    const renderTableRows = () => {
        return <tbody>{tableRows}</tbody>;
    };

    return {
        renderTableRows,
    };
};
