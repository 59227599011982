import {ArrowDown, ArrowUp,} from "lucide-react";
import SegmentedProgressBar from "@/components/common/Wrappers/SegmentedProgressBar/SegmentedProgressBar.jsx";
import {cn, getRelativeTimeDifference} from "@/lib/utils.js";
import {useEffect, useState} from "react";
import CountUp from "react-countup";
import {timeGap30Minutes} from "@/pages/Live/utils.js";
import {Separator} from "@/components/ui/separator.jsx";
import {useCounts} from "@/pages/Dashboard/useCounts.js";
import TooltipWrapper from "@/components/common/Wrappers/Tooltip/TooltipWrapper.jsx";
import {DotFilledIcon} from "@radix-ui/react-icons";
import {formatNumber} from "@/pages/Dashboard/utils.js";

const ProcessorsCard = ({item, showActions = true}) => {
    const [endCount, setEndCount] = useState(item?.processed?.today || 0);
    const [startCount, setStartCount] = useState(item.processed.today || 0);
    const [history, setHistory] = useState([]);
    const [rate, setRate] = useState(0);
    const [downloaderStatus, setDownloaderStatus] = useState(undefined);
    const [isAlert, setIsAlert] = useState(false);
    const [isWarning, setIsWarning] = useState(false);

    const valueType =
        item?.processed?.comparison === "NaN"
            ? "equal"
            : item?.processed?.comparison?.startsWith("-")
                ? "negative"
                : "positive";

    const isInActive = valueType === "equal";


    useEffect(() => {

        setStartCount(endCount);
        setEndCount(item?.processed?.today);

        setIsWarning(
            item?.queue.queue > 0 &&
            timeGap30Minutes(JSON.parse(item?.queue?.downloaderStatus)?.time));
        setIsAlert(item?.download_toggle === "0");

        const downloaderStatus = JSON.parse(item?.queue?.downloaderStatus);
        const success = downloaderStatus?.success;
        const failed = downloaderStatus?.failed;
        const percentageSuccess = (success / (success + failed)) * 100;

        setDownloaderStatus({success, failed, percentageSuccess});

    }, [item]);

    useCounts({history, setHistory, endCount, setRate});

    return (
        <div
            className={"border rounded-xl w-full h-fit p-4 shadow bg-background"}
        >
            <div
                className={"flex w-full justify-between items-center font-semibold"}
            >
                <div className={"flex justify-between items-center"}>
                    <div className={"flex items-center gap-2"}>
                        <div className={"relative"}>
                            {(isWarning || isAlert) && (
                                <div
                                    className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"></div>
                            )}
                            <div
                                className={cn("w-[12px] h-[12px] rounded-full", {
                                    "bg-green-600 animate-pulse": !isWarning,
                                    "bg-yellow-600 animate-pulse": isAlert,
                                    "bg-red-600": isWarning,
                                })}
                            ></div>
                        </div>
                        <p className={"text-muted-foreground"}>#{item?.id}</p>
                    </div>
                </div>
            </div>
            <div className={"flex items-center justify-between"}>
                <div className={"pt-2 pb-1 flex flex-row items-center gap-3"}>
                    <div
                        className={cn("text-2xl text-foreground font-semibold", {
                            "text-muted-foreground": isInActive,
                        })}
                    >
                        <CountUp
                            start={startCount}
                            end={endCount}
                            duration={5}
                            separator=","
                        />
                    </div>
                </div>
                <div
                    className={cn(
                        "flex items-center gap-1 text-foreground font-semibold",
                        {},
                    )}
                >
                    <TooltipWrapper
                        component={<div
                            className={cn("flex flex-row items-center", {
                                "text-text-success": valueType === "positive",
                                "text-text-destructive": valueType === "negative",
                            })}
                        >
                            <p
                                className={cn(
                                    "p-[1px] rounded-full flex items-center justify-center",
                                    {
                                        "bg-green-200": valueType === "positive",
                                        "bg-red-100": valueType === "negative",
                                    },
                                )}
                            >
                                {valueType === "positive" ? (
                                    <ArrowUp size={15}/>
                                ) : (
                                    <ArrowDown size={15}/>
                                )}
                            </p>
                            <p className={"pt-[1px]"}>{item?.processed?.comparison}%</p>
                        </div>}
                        text={<p className={"text-xs"}>
                            vs {formatNumber(item?.processed?.yesterday)} yesterday
                        </p>}
                    />

                    <p className={"mt-[1px] text-md"}>{Intl.NumberFormat().format(Math.round(rate))}/min</p>

                </div>
            </div>
            <Separator className={"w-full mt-2"}/>
            <div className={"grid grid-cols-2 gap-5 mt-3"}>
                <SegmentedProgressBar
                    current={item.queue.queue || 0}
                    total={item.queue_threshold || 0}
                    title={"q"}
                />
                <SegmentedProgressBar
                    current={item.queue.downloaded || 0}
                    total={item.queue_threshold || 0}
                    title={"d"}
                />
            </div>
            {showActions && (
                <div
                    className={
                        "flex w-full justify-end items-center font-normal mt-2 gap-3"
                    }
                >
                    <TooltipWrapper
                        component={
                            <div className={"flex flex-row items-center"}>
                                <p className={"text-muted-foreground text-xs"}>
                                    Last batch: {getRelativeTimeDifference(
                                    JSON.parse(item?.queue?.downloaderStatus)?.time,
                                )}
                                </p>
                                <DotFilledIcon className={cn(
                                    "w-5 h-5 text-muted-foreground",
                                    `${downloaderStatus?.percentageSuccess ?? 0 > 80 ? "text-text-success" : "text-text-destructive"}`)}/>

                            </div>
                        }

                        text={
                            <div className={
                                cn("flex flex-row gap-5")}>
                                <p>{downloaderStatus?.success ?? 0} / {downloaderStatus?.failed ?? 0}</p>
                                <p> {downloaderStatus?.percentageSuccess.toFixed(2) ?? 0}%</p>
                            </div>
                        }
                    >

                    </TooltipWrapper>


                </div>
            )}
        </div>
    );
};
export default ProcessorsCard;
