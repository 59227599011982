// processorSaga.js
import API from "@/axios/axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    GET_PROCESSOR_DATA,
    GET_PROCESSOR_DATA_SUCCESS,
    GET_PROCESSOR_DATA_FAILURE,
    UPDATE_PROCESSOR,
    UPDATE_PROCESSOR_SUCCESS,
    UPDATE_PROCESSOR_FAILURE,
    ADD_PROCESSOR_SUCCESS,
    ADD_PROCESSOR_FAILURE,
    ADD_PROCESSOR,
    DELETE_PROCESSOR_SUCCESS,
    DELETE_PROCESSOR_FAILURE,
    DELETE_PROCESSOR,
} from "./actionTypes";

function* fetchProcessorData() {
    try {
        const response = yield call(API.get, "/processor/all");
        yield put({ type: GET_PROCESSOR_DATA_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_PROCESSOR_DATA_FAILURE, error: error.message });
    }
}

function* updateProcessor({ payload }) {
    try {
        // Use the complete payload for the API call
        yield call(API.put, `/processor/update`, payload);

        // Dispatch success action with the updated processor
        yield put({ type: UPDATE_PROCESSOR_SUCCESS, payload });
    } catch (error) {
        yield put({ type: UPDATE_PROCESSOR_FAILURE, error: error.message });
    }
}

function* addProcessor({ payload }) {
    try {
        // Call the API to add a new processor
        const response = yield call(API.post, "/processor/add", payload);

        // Dispatch success action with the new processor data
        yield put({ type: ADD_PROCESSOR_SUCCESS, payload: response.data.data });
        yield call(fetchProcessorData);
    } catch (error) {
        yield put({ type: ADD_PROCESSOR_FAILURE, error: error.message });
    }
}

function* deleteProcessor({ payload }) {
    try {
        // Call the delete API
        yield call(API.delete, `/processor/delete?id=${payload}`);
        // Dispatch success action
        yield put({ type: DELETE_PROCESSOR_SUCCESS, payload });
    } catch (error) {
        // Dispatch failure action
        yield put({ type: DELETE_PROCESSOR_FAILURE, error: error.message });
    }
}

export default function* processorSaga() {
    yield all([
        takeLatest(GET_PROCESSOR_DATA, fetchProcessorData),
        takeLatest(UPDATE_PROCESSOR, updateProcessor),
        takeLatest(ADD_PROCESSOR, addProcessor), // Add this line to watch for the ADD_PROCESSOR action
        takeLatest(DELETE_PROCESSOR, deleteProcessor),
    ]);
}

