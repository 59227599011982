// actionTypes.js
export const GET_PROCESSOR_DATA = "GET_PROCESSOR_DATA";
export const GET_PROCESSOR_DATA_SUCCESS = "GET_PROCESSOR_DATA_SUCCESS";
export const GET_PROCESSOR_DATA_FAILURE = "GET_PROCESSOR_DATA_FAILURE";

export const UPDATE_PROCESSOR = "UPDATE_PROCESSOR";
export const UPDATE_PROCESSOR_SUCCESS = "UPDATE_PROCESSOR_SUCCESS";
export const UPDATE_PROCESSOR_FAILURE = "UPDATE_PROCESSOR_FAILURE";

export const ADD_PROCESSOR = "ADD_PROCESSOR";
export const ADD_PROCESSOR_SUCCESS = "ADD_PROCESSOR_SUCCESS";
export const ADD_PROCESSOR_FAILURE = "ADD_PROCESSOR_FAILURE";

export const DELETE_PROCESSOR = "DELETE_PROCESSOR";
export const DELETE_PROCESSOR_SUCCESS = "DELETE_PROCESSOR_SUCCESS";
export const DELETE_PROCESSOR_FAILURE = "DELETE_PROCESSOR_FAILURE";