import SkeletonWrapper from "@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx";
import Charts from "@/components/common/Charts/Charts.jsx";

const GraphSection = ({loading, graphData}) => {

    return (
        <div className={"flex flex-col gap-3"}>
            {loading && graphData.length === 0 ? (
                <SkeletonWrapper
                    width={"100%"}
                    height={"44vh"}
                    count={"1"}
                    className={"w-full"}
                />
            ) : (
                <Charts title={"scraped"} data={graphData.scraped || []}/>
            )}

            {loading && graphData.length === 0 ? (
                <SkeletonWrapper
                    width={"100%"}
                    height={"44vh"}
                    count={"1"}
                    className={"w-full"}
                />
            ) : (
                <Charts title={"processed"} data={graphData.processed || []}/>
            )}

            {loading && graphData.length === 0 ? (
                <SkeletonWrapper
                    width={"100%"}
                    height={"44vh"}
                    count={"1"}
                    className={"w-full"}
                />
            ) : (
                <Charts title={"indexed"} data={graphData.indexed || []}/>
            )}

        </div>
    )
}

export default GraphSection;