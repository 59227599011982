import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

import {cn} from "@/lib/utils";
import {ChevronRight} from "lucide-react";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef(({className, ...props}, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={cn("border-b", className)}
        {...props}
    />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef(
    ({className, children, ...props}, ref) => (
        <AccordionPrimitive.Header className="flex">
            <AccordionPrimitive.Trigger
                ref={ref}
                className={cn(
                    "flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-90 [&[data-state=open]>svg]:text-text-success",
                    className,
                )}
                {...props}
            >
                <ChevronRight
                    strokeWidth={2.5}
                    className="h-5 w-5 shrink-0 transition-transform duration-200"
                />
                {children}
            </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
    ),
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef(
    ({className, children, ...props}, ref) => (
        <AccordionPrimitive.Content
            ref={ref}
            className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
            {...props}
        >
            <div className={cn("pb-4 pt-0", className)}>{children}</div>
        </AccordionPrimitive.Content>
    ),
);
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export {Accordion, AccordionItem, AccordionTrigger, AccordionContent};
