import IconsMap from '@/components/IconsMap/IconsMap';
import {Button} from '@/components/ui/button';
import {Switch} from '@/components/ui/switch';
import {Image, Plus, Search, Trash2, Video} from 'lucide-react';
import React, {useState, useMemo} from 'react';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import useProcessorStore from './processorStore';
import {useStore} from 'zustand';
import {deleteProcessor, updateProcessor} from '@/redux/Processor/actions';
import {useDispatch} from 'react-redux';
import ProcessorForm from './components/ProcessorForm';

const Processor = () => {
    const {processorData} = useStore(useProcessorStore);
    const dispatch = useDispatch();

    // States for selected source and search term
    const [selectedSource, setSelectedSource] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    // Extract unique sources and remove 'source-' prefix
    const uniqueSources = useMemo(() => {
        const sources = processorData.map(proc => proc.source);
        const unique = Array.from(new Set(sources));
        // Remove 'source-' prefix
        return unique.map(src => src.replace(/^source-/, ''));
    }, [processorData]);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filtered processor data based on selected source and search term
    const filteredProcessorData = useMemo(() => {
        let data = processorData;
        if (selectedSource) {
            data = data.filter(proc => proc.source === `source-${selectedSource}`);
        }
        if (searchTerm) {
            data = data.filter(proc => proc.id.toLowerCase().includes(searchTerm.toLowerCase()));
        }
        return data;
    }, [processorData, selectedSource, searchTerm]);

    // Sort the filtered data alphabetically by ID
    const sortedProcessorData = useMemo(() => {
        return [...filteredProcessorData].sort((a, b) => a.id.localeCompare(b.id));
    }, [filteredProcessorData]);

    const handleToggle = (processor) => {
        const updatedProcessor = {
            ...processor, // Include all keys from the existing processor object
            is_active: processor.is_active === 1 ? 0 : 1, // Update only the `is_active` key
        };

        // Dispatch the updated processor object as the payload
        dispatch(updateProcessor(updatedProcessor));
    };

    const handleDelete = (id) => {
        dispatch(deleteProcessor(id));
    };


    return (
        <div className="container p-4 ">
            <div className="overflow-x-auto">
                <div className='flex justify-between'>
                    <div className='flex items-center gap-6 mb-8'>
                        <div className='text-2xl'>
                            Processors
                        </div>
                        <Sheet>
                            <SheetTrigger>
                                <Button>
                                    <Plus className='w-4 h-4'/>Add New
                                </Button>
                            </SheetTrigger>
                            <SheetContent className="!max-w-[40%]">
                                <SheetHeader>
                                    <SheetTitle className='!text-2xl mb-4'>Add Processor</SheetTitle>
                                    <SheetDescription>
                                        <ProcessorForm/>
                                    </SheetDescription>
                                </SheetHeader>
                            </SheetContent>
                        </Sheet>
                    </div>
                    <div className="flex gap-4 mb-8">
                        <span className="flex items-center text-gray-500">
                            <Search className="h-5 w-5"/>
                        </span>
                        <input
                            type="text"
                            placeholder="Search by ID..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="border border-gray-300 px-4 rounded-lg w-64 focus:outline-none focus:border-zinc-700"
                        />
                        <DropdownMenu>
                            <DropdownMenuTrigger
                                className="border border-gray-500 text-zinc-600 rounded-lg px-2 py-1 w-32 text-left">
                                {selectedSource ? selectedSource.charAt(0).toUpperCase() + selectedSource.slice(1) : "All Sources"}
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-48">
                                <DropdownMenuItem onClick={() => setSelectedSource('')}>
                                    All Sources
                                </DropdownMenuItem>
                                {uniqueSources.map((source, idx) => (
                                    <DropdownMenuItem key={idx} onClick={() => setSelectedSource(source)}>
                                        {source.charAt(0).toUpperCase() + source.slice(1)}
                                    </DropdownMenuItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>


                <div className="overflow-y-auto max-h-96">
                    <table className="w-full text-center border-collapse shadow-lg rounded-lg overflow-hidden bg-white">
                        <thead>
                        <tr className="bg-gray-300 text-gray-700">
                            <th className="w-[20%] text-left px-4 py-3 border-b border-gray-200 font-semibold">ID</th>
                            <th className="px-4 py-3 border-b border-gray-200 font-semibold">Active</th>
                            <th className="w-[15%] px-4 py-3 border-b border-gray-200 font-semibold">Queue Threshold
                            </th>
                            <th className="px-4 w-24 py-3 border-b border-gray-200 font-semibold">Type</th>
                            <th className="text-center px-4 py-3 border-b border-gray-200 font-semibold">Source</th>
                            <th className="px-4 py-3 border-b border-gray-200 font-semibold">Proxy</th>
                            <th className="px-4 py-3 border-b border-gray-200 font-semibold">Batch Size</th>
                            <th className="px-4 py-3 border-b border-gray-200 font-semibold">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sortedProcessorData.map((processor, index) => (
                            <tr
                                key={processor.id} // Use unique identifier
                                className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors duration-200`}
                            >
                                <td className="text-left px-4 py-5 border-b border-gray-200 text-gray-800">{processor.id}</td>
                                <td className="px-4 py-2 border-b border-gray-200 text-gray-800">
                                    <Switch
                                        checked={processor.is_active === 1}
                                        onClick={() => handleToggle(processor)}
                                    />
                                </td>
                                <td className="px-4 py-2 border-b border-gray-200 text-gray-800">{processor.queue_threshold}</td>
                                <td className="px-4 py-2 text-sm border-b border-gray-200 text-gray-800">
                                        <span className="flex items-center gap-2 border-2 rounded-md p-1">
                                            {processor.type === "image" ? <Image className="w-4 h-4"/> :
                                                <Video className="w-4 h-4"/>}
                                            <span>{processor.type === "image" ? "Image" : "Video"}</span>
                                        </span>
                                </td>
                                <td className="px-4 py-6 border-b border-gray-200 flex justify-center items-center">
                                        <span className="gap-2">
                                            {IconsMap[processor.source.replace('source-', '')]
                                                ? IconsMap[processor.source.replace('source-', '')]({className: 'w-5 h-5'})
                                                : null}
                                        </span>
                                </td>
                                <td className="px-4 py-2 border-b border-gray-200 text-gray-800">
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger className='border rounded-md p-1 bg-zinc-100'>
                                                {`${processor.proxy?.substring(0, 18)}...`}
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{processor.proxy}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </td>
                                <td className="px-4 py-2 border-b border-gray-200 text-gray-800">{processor.download_batch_size ? processor.download_batch_size : "N/A"}</td>
                                <td className="px-4 py-2 border-b border-gray-200">
                                    <AlertDialog>
                                        <AlertDialogTrigger asChild>
                                            <Button variant="secondary"
                                                    className="p-1 w-8 h-8 transition-transform transform hover:scale-110 hover:bg-red-500 hover:text-white">
                                                <Trash2 className="w-5 h-5"/>
                                            </Button>
                                        </AlertDialogTrigger>
                                        <AlertDialogContent>
                                            <AlertDialogHeader>
                                                <AlertDialogTitle>You want to delete {processor.id} !</AlertDialogTitle>
                                                <AlertDialogDescription>
                                                    Are you absolutely sure?
                                                </AlertDialogDescription>
                                            </AlertDialogHeader>
                                            <AlertDialogFooter>
                                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                                <AlertDialogAction onClick={() =>
                                                    handleDelete(processor.id)
                                                }>Delete</AlertDialogAction>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>

                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
};

export default Processor;
