import { create } from "zustand";

const usePostProcessingStore = create((set, get) => ({
    postProcessingData: { graph_data: {} },
    setPostProcessingData: (data) => set({postProcessingData: data}),

    loading: false,
    setLoading: (loading) => set({loading}),
}))

export default usePostProcessingStore;
