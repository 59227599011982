import { Checkbox } from "@/components/ui/checkbox";

export function CheckboxWrapper({ id, label, ...props }) {
  return (
    <div className="flex items-center space-x-2 cursor-pointer">
      <Checkbox id={id} {...props} />
      <label
        htmlFor={id}
        className="text-black text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  );
}
