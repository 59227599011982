import {useEffect, useRef, useState} from "react";
import CountUp from "react-countup";
import _ from "lodash/fp.js";
import {ArrowDown, ArrowUp} from "lucide-react";
import {cn, toShortForm} from "@/lib/utils.js";
import IconsMap from "@/components/IconsMap/IconsMap";
import {useStore} from "zustand";
import updateRateStore from "@/components/common/updateRateStore";
import TooltipWrapper from "@/components/common/Wrappers/Tooltip/TooltipWrapper.jsx";
import {DateTime} from "luxon";

const PopoverCardSection = ({
                                title,
                                countsToday,
                                countsYesterday,
                                type,
                            }) => {
    const [Icon] = useState(IconsMap[title]());
    const [endCount, setEndCount] = useState(countsToday);
    const [startCount, setStartCount] = useState(countsYesterday);
    const [difference, setDifference] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [rate, setRate] = useState(0);
    const countUpRef = useRef(null);
    const [hasStarted, setHasStarted] = useState(false);
    const {data: rateData} = useStore(updateRateStore)

    useEffect(() => {
        if (hasStarted) {
            setStartCount(endCount);
            setEndCount(countsToday);
        } else {
            setStartCount(countsToday);
            setEndCount(countsToday);
            setHasStarted(true);
        }

    }, [countsToday, countsYesterday]);

    // // Update rate every 3 seconds, based on the sliding window of 1 minute
    // useCounts({ history, setHistory, endCount, setRate });

    useEffect(() => {
        if (!rateData)
            return;

        const key = `${type}_${title}`;
        const rate = rateData[key]?.current_rate ?? 0;
        setRate(toShortForm(rate));

    }, [rateData]);

    useEffect(() => {
        // Calculate estimated count for today based on countsToday and time elapsed
        const secondsElapsedToday = DateTime.utc().diff(DateTime.utc().startOf("day"), "seconds").seconds;
        
        const estimatedCount = (countsToday / secondsElapsedToday) * 86400;

        const estimatedDifference = estimatedCount - countsYesterday;
        setDifference(estimatedDifference);

        setPercentage((estimatedDifference / countsYesterday) * 100);
    }, []);

    return (
        <div className={"w-full flex flex-row gap-3 px-4 py-2"}>
            {Icon}
            <div className={"w-full"}>
                <p className={"text-sm text-gray-600"}>{_.capitalize(title)}</p>
                <div className={"flex flex-row text-lg font-semibold justify-between"}>
                    <CountUp
                        ref={countUpRef}
                        start={startCount}
                        end={endCount}
                        duration={10}
                        separator=","
                        preserveValue={true}
                    />
                    <div className={"flex flex-row gap-3 text-sm font-normal"}>
                        <TooltipWrapper
                            component={<div
                                className={cn("flex flex-row items-center gap-1", {
                                    "text-text-success": difference > 0,
                                    "text-text-destructive": difference < 0,
                                })}
                            >
                                <p
                                    className={cn(
                                        "p-[1px] rounded-full flex items-center justify-center",
                                        {
                                            "bg-green-200": difference > 0,
                                            "bg-red-100": difference < 0,
                                        },
                                    )}
                                >
                                    {difference > 0 ? (
                                        <ArrowUp size={15}/>
                                    ) : (
                                        <ArrowDown size={15}/>
                                    )}
                                </p>
                                <p>{percentage.toFixed(2)}%</p>
                                <p className={"text-gray-500 text-xs w-20"}>{rate} /min</p>
                            </div>}
                            text={<p className={"text-xs"}>
                                vs {toShortForm(countsYesterday)} yesterday
                            </p>}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PopoverCardSection;
