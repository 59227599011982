import axios from "axios";

const baseUrl = "https://api-processor.goloti.com";
// const baseUrl = "http://192.168.1.30:1403";

const API = axios.create({baseURL: baseUrl});

API.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default API;
