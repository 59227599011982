const LiveIcon = ({ width = 30, height = 30 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.92085 23.0792C1.20835 18.3667 1.20835 10.6334 5.92085 5.9209"
        stroke="#079B48"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.42495 19.5745C6.64578 16.7954 6.64578 12.2037 9.42495 9.30371"
        stroke="#079B48"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4999 16.9163C15.8346 16.9163 16.9166 15.8344 16.9166 14.4997C16.9166 13.165 15.8346 12.083 14.4999 12.083C13.1652 12.083 12.0833 13.165 12.0833 14.4997C12.0833 15.8344 13.1652 16.9163 14.4999 16.9163Z"
        stroke="#079B48"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.575 9.4248C22.3541 12.204 22.3541 16.7956 19.575 19.6956"
        stroke="#079B48"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0791 5.9209C27.7916 10.6334 27.7916 18.2459 23.0791 22.9584"
        stroke="#079B48"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default LiveIcon;
