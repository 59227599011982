import {create} from 'zustand';

const updateRateStore = create((set,) => ({
    // Data object to hold history and current_rate for each key
    data: {},
    isAuth: false,
    isUnlocked: false,
    toggleUnlocked: (isUnlocked) => set({ isUnlocked }),
    toggleAuth: () => set((state) => ({ isAuth: !state.isAuth })),

    // Method to update the rate calculation and store history for each key
    updateData: (key, endCount) => {
        set((state) => {
            const currentTime = Date.now();

            // Get the current data for this key or initialize it if not present
            const currentData = state.data[key] || {history: [], current_rate: 0};

            // Append the new data point (timestamp and count) to the history
            const updatedHistory = [...currentData.history, {timestamp: currentTime, count: endCount}];

            // Filter the history to only keep entries from the last 1 minute (60 seconds)
            const oneMinuteAgo = currentTime - 60 * 1000;
            const filteredHistory = updatedHistory.filter(entry => entry.timestamp >= oneMinuteAgo);

            let avgRate = 0;
            // Calculate the rate based on the first and last entries in the filtered history
            if (filteredHistory.length > 1) {
                const firstEntry = filteredHistory[0];
                const lastEntry = filteredHistory[filteredHistory.length - 1];

                const timeElapsedMinutes = (lastEntry.timestamp - firstEntry.timestamp) / (60 * 1000); // in minutes
                const countDiff = lastEntry.count - firstEntry.count;

                // Per-minute rate calculation
                if (timeElapsedMinutes > 0) {
                    avgRate = countDiff / timeElapsedMinutes;
                }
            }

            // Store the new data in the state
            return {
                data: {
                    ...state.data,
                    [key]: {
                        history: filteredHistory,   // Only store the last 1 minute of history
                        current_rate: avgRate,      // Store the per-minute rate
                    },
                },
            };
        });
    },
}));

export default updateRateStore;
