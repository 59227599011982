import {useStore} from "zustand";
import useComaprisionStore from "./userComparisionStore";
import MultiSelect from "@/components/ui/multi-select";
import {DatePickerWithRange} from "@/components/common/DateRangePicker/DatePickerWithRange";
import {Button} from "@/components/ui/button";
import {useCallback} from "react";
import API from "@/axios/axios";
import {Skeleton} from "@/components/ui/skeleton";

export const useForm = () => {
    const {
        artists,
        comparisionView,
        formData,
        setFormData,
        setNotes,
        isFormLoading,
        setIsFormLoading,
        setSearchResults,
        isArtistsLoading,
    } = useStore(useComaprisionStore);

    const handleSelectChange = (team, artistId, check) => {
        setFormData({
            ...formData,
            [team]: check
                ? [...formData[team], artistId]
                : formData[team].filter((id) => id !== artistId),
        });
    };

    const handleSelectAll = (team, artists, check) => {
        const teamData = formData[team];
        if (check) {
            setFormData({
                ...formData,
                [team]: [...teamData, ...artists.map((artist) => artist.user_id)],
            });
        } else {
            setFormData({
                ...formData,
                [team]: teamData.filter((id) => !artists.map((artist) => artist.user_id).includes(id)),
            });
        }
    };

    const handleDateChange = (dates) => {
        setFormData({...formData, dates});
    };

    const fetchSearchResults = useCallback(async () => {
        try {
            setSearchResults({
                teamA: null,
                teamB: null,
            });
            setNotes([]);

            let references = ["left", "right"];

            // if (detailedView) {
            // - #TODO: logic for removing right reference
            // }

            setIsFormLoading(true);

            const requests = references.map(async (reference) => {
                const userIds = reference === "left" ? formData.teamA : formData.teamB;

                if (userIds.length > 0) {
                    const queryParams = `?user_ids=${userIds}&start_date=${formData.dates.from}&end_date=${formData.dates.to}&reference=${reference}`;

                    const {data, status} = await API.get(
                        `/artists/compare${queryParams}`
                    );

                    if (status === 200 && data?.data) {
                        return {reference, data: data.data};
                    }
                } else {
                    return {reference, data: null};
                }
            });

            const results = await Promise.all(requests);

            const newCompareData = results.reduce(
                (acc, {reference, data}) => ({
                    ...acc,
                    [reference === "left" ? "teamA" : "teamB"]: data,
                }),
                {}
            );

            setSearchResults({...newCompareData});
        } catch (err) {
            console.error("Error in fetchSearchResults:", err);
        } finally {
            setIsFormLoading(false);
        }
    }, [formData]);

    const renderForm = () => {
        if (isArtistsLoading) {
            return (
                <div className="flex items-end gap-4">
                    <Skeleton className="w-64 h-16"/>
                    {comparisionView && (
                        <>
                            <span className="font-regular text-xl h-9">vs</span>
                            <Skeleton className="w-64 h-16"/>
                        </>
                    )}
                    <Skeleton className="w-64 h-16"/>
                    <Skeleton className="w-24 h-10"/>
                </div>
            );
        }

        return (
            <div className="flex items-end gap-4">
                <MultiSelect
                    id="teamA"
                    selectedValues={formData.teamA}
                    data={artists}
                    onChange={handleSelectChange}
                    onSelectAllChange={handleSelectAll}
                    label={
                        <span className="text-sm mb-1 text-gray-600 font-normal">
              {comparisionView ? "Team A" : "Artists"}
            </span>
                    }
                />

                {comparisionView && (
                    <>
                        <span className="font-regular text-xl h-9">vs</span>
                        <MultiSelect
                            id="teamB"
                            selectedValues={formData.teamB}
                            data={artists}
                            onChange={handleSelectChange}
                            onSelectAllChange={handleSelectAll}
                            label={
                                <span className="text-sm mb-1 text-gray-600 font-normal">
                  Team B
                </span>
                            }
                        />
                    </>
                )}

                <DatePickerWithRange
                    selectedDates={formData.dates}
                    onChange={handleDateChange}
                />

                <Button
                    onClick={fetchSearchResults}
                    disabled={isFormLoading || !formData.teamA?.length}
                    className="h-10"
                >
                    Search
                </Button>
            </div>
        );
    };

    return {
        formData,
        handleSelectChange,
        handleDateChange,
        renderForm,
    };
};
