import {formatNumber} from "@/pages/Dashboard/utils.js";
import {DownloadCloud, ListStart} from "lucide-react";
import {cn} from "@/lib/utils.js";

const SegmentedProgressBar = ({current, total, title}) => {
    const percentage = Math.floor((current / total) * 100);
    const segments = 30; // Number of segments in the bar
    const filledSegments = Math.floor((percentage / 100) * segments);

    const gridColsTemplate = `repeat(${segments}, 1fr)`;

    return (
        <div className={"w-full"}>
            <div className={"w-full text-muted-foreground pb-3 text-sm"}>
                {title === "q" ? (
                    <div className={"flex items-center gap-1"}>
                        <div className={"p-[5px] rounded-full bg-muted"}>
                            <ListStart className={"w-[15px] h-[15px] text-foreground"}/>
                        </div>
                        <p>Queued</p>
                    </div>
                ) : (
                    <div className={"w-full flex flex-row justify-between"}>
                        <div className={"flex items-center gap-2"}>
                            <div className={"p-[5px] rounded-full bg-muted"}>
                                <DownloadCloud className={"w-[15px] h-[15px] text-foreground"}/>
                            </div>
                            <p>Downloaded</p>
                        </div>

                    </div>
                )}
            </div>
            <div className={"flex items-center justify-between text-xs pb-[2px]"}>
                <div className=" text-gray-500">
                    {formatNumber(current)} / {formatNumber(total)}
                </div>
                <div
                    className={cn({
                        "text-green-600": percentage > 0,
                        "text-muted-foreground": percentage === 0 || !percentage,
                    })}
                >
                    {percentage || 0}%
                </div>
            </div>
            <div className={"flex items-center gap-1 justify-between"}>
                <div className={cn(
                    "grid w-full",
                )}
                     style={{
                         gridTemplateColumns: gridColsTemplate,
                     }}>
                    {[...Array(segments)].map((_, index) => (
                        <div
                            key={index}
                            className={`h-6 w-[3px] rounded-md ${
                                index < filledSegments ? "bg-slate-500" : "bg-gray-100"
                            }`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SegmentedProgressBar;
