// actions.js
import * as actionTypes from "./actionTypes.js";

export const getProcessorData = () => ({
    type: actionTypes.GET_PROCESSOR_DATA,
});

export const updateProcessor = (updatedProcessor) => ({
    type: actionTypes.UPDATE_PROCESSOR,
    payload: updatedProcessor,
});

export const addProcessor = (processorData) => ({
    type: actionTypes.ADD_PROCESSOR,
    payload: processorData,
});

export const deleteProcessor = (id) => ({
    type: actionTypes.DELETE_PROCESSOR,
    payload: id,
});
