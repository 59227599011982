import { getPostProcessing } from '@/redux/PostProcessing/actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usePostProcessingStore from './postProcessingStore';
import Charts from '@/components/common/Charts/Charts';
import { Skeleton } from '@/components/ui/skeleton';
import { Card, CardContent, CardTitle } from '@/components/ui/card';

const PostProcessing = () => {
    const dispatch = useDispatch();
    const postProcessingData = usePostProcessingStore((state) => state.postProcessingData);
    const loading = usePostProcessingStore(state => state.loading);
    const { batch_sizes = [], statuses = [] } = postProcessingData || {};

    useEffect(() => {
        dispatch(getPostProcessing());
    }, [dispatch]);

    const result = postProcessingData.graph_data
        ? Object.entries(postProcessingData.graph_data).reduce((acc, [date, data]) => {
            const categories = {};

            for (const [key, value] of Object.entries(data)) {
                const cleanedLabel = key.replace("sr-post-processing-", "");
                categories[cleanedLabel] = value || 0;
            }

            // Calculate the total
            categories.total = Object.values(categories).reduce((sum, value) => sum + value, 0);

            acc[date] = categories;

            return acc;
        }, {})
        : {};

    return (
        <>
            <div className='w-full'>
                <div className='text-2xl mb-5 font-bold'>
                    Post Processing
                </div>


                {/* Loader or Chart */}
                {loading ? (
                    <Skeleton className="w-full h-32 rounded-md" />
                ) : (
                    <Charts data={result} postProcessingTitle={true}/>
                )}
            </div>
        </>
    );
};

export default PostProcessing;
