import _ from "lodash/fp.js";

export const processCategoryWiseCounts = (categoryWiseCounts) => {
  const processedData = Object.keys(categoryWiseCounts).map((key) => {
    const items = categoryWiseCounts[key].items;
    const countsToday = _.sumBy(
      (item) => Number(item.processed.today) || 0,
      items,
    );
    const countsYesterday = _.sumBy(
      (item) => Number(item.processed.yesterday) || 0,
      items,
    );

    return {
      source: key,
      processors: items,
      countsToday,
      countsYesterday,
    };
  });

  return processedData.sort((a, b) => b.countsToday - a.countsToday);
};

export const getLiveCounts = (data) => {
  return data.reduce(
      (acc, item) => {
        acc.countsToday += Number(item.processed.today) || 0;
        acc.countsYesterday += Number(item.processed.yesterday) || 0;
        return acc;
      },
      {countsToday: 0, countsYesterday: 0, source: "live"},
  );
};

export const formatNumber = (num) => {
  if (!num) return 0;
  if (num >= 1e9) return (num / 1e9).toFixed(2) + "B";
  if (num >= 1e6) return (num / 1e6).toFixed(2) + "M";
  if (num >= 1e3) return (num / 1e3).toFixed(2) + "k";
  return num.toString();
};

export const processScrapersWithTotal = (scrapers) => {
  const scrapersArray = Object.entries(scrapers);

  const totalStats = _.sumBy(
    ([, item]) => Number(item.stats) || 0,
    scrapersArray,
  );
  const totalYesterdayStats = _.sumBy(
    ([, item]) => Number(item.yesterday_stats) || 0,
    scrapersArray,
  );

  const sortedScrapersArray = scrapersArray.sort(
    ([, a], [, b]) => b.stats - a.stats,
  );

  const sortedScrapers = Object.fromEntries(sortedScrapersArray);

  return {
    live: {
      stats: totalStats,
      yesterday_stats: totalYesterdayStats,
    },
    ...sortedScrapers,
  };
};
