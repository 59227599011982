import { useStore } from "zustand";
import dashboardStore from "@/pages/Dashboard/dashboardStore.js";
import { SpeedOMeterSection } from "@/pages/Dashboard/components/Overview/SpeedOMeterSection.jsx";
import GraphSection from "@/pages/Dashboard/components/Overview/GraphSection.jsx";
import { ArrowBigRightDashIcon, BugIcon, CpuIcon } from "lucide-react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs.jsx";
import { useEffect, useState } from "react";
import Charts from "@/components/common/Charts/Charts.jsx";
import updateRateStore from "@/components/common/updateRateStore.js";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card.jsx";
import { toShortForm } from "@/lib/utils.js";
import IconsMap from "@/components/IconsMap/IconsMap.jsx";
import _ from "lodash/fp.js";

const categories = [
  "adult",
  "instagram",
  "tiktok",
  "pinterest",
  "reddit",
  "ebay",
  "etsy",
  "youtube",
];

const Dashboard = () => {
  const { graphData, loading } = useStore(dashboardStore);
  const { data: rateData } = useStore(updateRateStore);

  const [categorizedData, setCategorizedData] = useState([]);

  useEffect(() => {
    if (Array.isArray(graphData)) return;
    if (!graphData || !rateData) return;

    let data = categories.map((category) => {
      const processorKey = `processors_${category}`;
      const scraperKey = `scrapers_${category}`;

      const processorRate = rateData[processorKey]?.current_rate ?? 0;
      const scraperRate = rateData[scraperKey]?.current_rate ?? 0;

      const diffValue = processorRate - scraperRate;

      const valueText =
        diffValue === 0
          ? "0"
          : diffValue < 0
          ? `-${toShortForm(Math.abs(diffValue))}`
          : `+${toShortForm(diffValue)}`;
      const valueColor =
        diffValue === 0 ? "#000" : diffValue < 0 ? "#dc2626" : "#16a34a";

      return {
        name: category,
        data: Object.keys(graphData?.processed)?.map((date) => {
          return {
            date: date,
            scraped: graphData?.scraped[date]?.[category] || 0,
            processed: graphData?.processed[date]?.[category] || 0,
            indexed: graphData?.indexed[date]?.[category] || 0,
          };
        }),
        scraperRate,
        processorRate,
        maxValue: Math.max(processorRate, scraperRate),
        value: processorRate,
        valueText,
        valueColor,
      };
    });

    setCategorizedData(data);
  }, [graphData, rateData]);

  return (
    <div className={"flex flex-col w-full gap-5"}>
      <div
        className={
          "flex flex-row w-full gap-3 items-center text-2xl font-semibold"
        }
      >
        <p>Ingest</p>
        <ArrowBigRightDashIcon size={30} />
        <p>Process</p>
        <ArrowBigRightDashIcon size={30} />
        <p>Index</p>
      </div>
      <div className={"flex flex-col w-full gap-3"}>
        <Tabs defaultValue="overview" className="w-full">
          <TabsList className="border mb-2">
            <TabsTrigger value="overview" className="text-base h-7 w-40">
              Overview
            </TabsTrigger>
            <TabsTrigger value="categorized" className="text-base h-7 w-40">
              Categorized
            </TabsTrigger>
          </TabsList>
          <TabsContent value="overview">
            <div className={"flex flex-col w-full gap-3"}>
              <div className={"w-full"}>
                <SpeedOMeterSection />
              </div>
              <div className={"w-full"}>
                <GraphSection loading={loading} graphData={graphData} />
              </div>
            </div>
          </TabsContent>

          <TabsContent value="categorized">
            <div className={"w-full flex flex-col gap-3"}>
              {categorizedData.map((item) => {
                return (
                  <div className={"flex flex-row w-full gap-3"} key={item.name}>
                    <Card className={"flex flex-col w-[25%] justify-between"}>
                      <CardHeader
                        className={"flex flex-row gap-3 items-center"}
                      >
                        {IconsMap[item.name]
                          ? IconsMap[item.name]({
                              height: "30px",
                              width: "30px",
                            })
                          : ""}
                        <p className={"text-2xl font-semibold"}>
                          {_.upperFirst(item.name)}
                        </p>
                      </CardHeader>
                      <CardContent className={"flex flex-row gap-2 items-end"}>
                        <p
                          className={"text-3xl font-bold"}
                          style={{
                            color: item?.valueColor,
                          }}
                        >
                          {item?.valueText}
                        </p>
                        <p className={"text-gray-500"}>per min</p>
                      </CardContent>
                      <CardFooter className={"flex flex-col w-full gap-5 mt-0"}>
                        <div className={"flex gap-3 w-full mt-5"}>
                          <div className="gap-1 flex flex-row justify-between w-full">
                            <div className={"flex flex-col gap-2"}>
                              <BugIcon className={"w-6 h-6 text-gray-600"} />
                              <p
                                className={
                                  "text-gray-600 text-xl w-40 font-bold"
                                }
                              >
                                {toShortForm(item?.scraperRate)} /min
                              </p>
                            </div>
                            <div className={"flex flex-col gap-2"}>
                              <CpuIcon className={"w-6 h-6 text-gray-600"} />
                              <p
                                className={
                                  "text-gray-600 text-xl w-40 font-bold"
                                }
                              >
                                {toShortForm(item?.processorRate)} /min
                              </p>
                            </div>
                          </div>
                        </div>
                      </CardFooter>
                    </Card>
                    <div className={"w-[75%]"}>
                      <Charts hideTitle={true} data={item.data} />
                    </div>
                  </div>
                );
              })}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Dashboard;
