import API from "@/axios/axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ARTISTS_DATA,
  GET_ARTISTS_DATA_SUCCESS,
  GET_ARTISTS_DATA_FAILURE,
} from "./actionTypes";

function* fetchArtists() {
  try {
    const response = yield call(
      API.get,
      "/artists/user-list"
    );
    yield put({
      type: GET_ARTISTS_DATA_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: GET_ARTISTS_DATA_FAILURE, error: error.message });
  }
}

export default function* userComparisionSaga() {
  yield all([takeLatest(GET_ARTISTS_DATA, fetchArtists)]);
}
