import dashboardReducer from "@/redux/Dashboard/reducer.js";
import { combineReducers } from "redux";
import processorReducer from "./Processor/reducer";
import userComparisionReducer from "./UserComparision/reducer";
import takedownReducer from "./TakedownStats/reducer";
import postProcessingReducer from "./PostProcessing/reducer";

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  processor: processorReducer,
  userComparision: userComparisionReducer,
  takedownStats: takedownReducer,
  postProcessing: postProcessingReducer
});
export default rootReducer;
