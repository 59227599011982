import TabContent from "@/pages/Live/components/Tabs/TabContent.jsx";
import ButtonWrapper from "@/components/common/Wrappers/Button/ButtonWrapper.jsx";
import { ArrowDownFromLine, ArrowUpFromLine } from "lucide-react";
import { useStore } from "zustand";
import liveStore from "@/pages/Live/liveStore.js";
import dashboardStore from "@/pages/Dashboard/dashboardStore.js";
import { TabsLive } from "./components/Tabs/TabsLive";

const Live = () => {
  const { expandAll, setExpandAll } = useStore(liveStore);
  const { loading } = useStore(dashboardStore);
  const tabDataProcessors = [
    {
      value: "all",
      component: (
        <>
          <TabContent tab={"all"} loading={loading} />
        </>
      ),
    },
    {
      value: "alert",
      component: (
        <>
          <TabContent tab={"alert"} />
        </>
      ),
    },
    {
      value: "warning",
      component: (
        <>
          <TabContent tab={"warning"} />
        </>
      ),
    },
  ];
  return (
    <div className={"w-full h-full pb-6"}>
      <TabsLive
        data={tabDataProcessors}
        prefix={"Processors Overview"}
        actions={
          <ButtonWrapper
            text={expandAll ? "Collapse All" : "Expand All"}
            icon={
              expandAll ? (
                <ArrowUpFromLine className={"w-[18px] h-[18px]"} />
              ) : (
                <ArrowDownFromLine className={"w-[18px] h-[18px]"} />
              )
            }
            onClick={() => setExpandAll(!expandAll)}
          />
        }
      />
    </div>
  );
};

export default Live;
