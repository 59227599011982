import {
    GET_PROCESSOR_DATA,
    GET_PROCESSOR_DATA_SUCCESS,
    GET_PROCESSOR_DATA_FAILURE,
    UPDATE_PROCESSOR_SUCCESS,
    UPDATE_PROCESSOR_FAILURE,
    ADD_PROCESSOR_SUCCESS,
    ADD_PROCESSOR_FAILURE,
    DELETE_PROCESSOR_SUCCESS,
    DELETE_PROCESSOR_FAILURE,
} from "./actionTypes";
import useProcessorStore from "@/pages/Processor/processorStore";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const processorReducer = (state = initialState, action) => {
    const processorStore = useProcessorStore.getState();

    switch (action.type) {
        case GET_PROCESSOR_DATA:
            processorStore.setLoading(true);
            return { ...state, loading: true, error: null };

        case GET_PROCESSOR_DATA_SUCCESS:
            processorStore.setProcessorData(action.payload);
            return { ...state, loading: false, data: action.payload, error: null };

        case GET_PROCESSOR_DATA_FAILURE:
            processorStore.setError(action.error);
            return { ...state, loading: false, error: action.error };

        case UPDATE_PROCESSOR_SUCCESS:
            processorStore.setProcessorData(
                state.data.map((processor) =>
                    processor.id === action.payload.id ? action.payload : processor
                )
            );
            return { ...state };

        case UPDATE_PROCESSOR_FAILURE:
            processorStore.setError(action.error);
            return { ...state, error: action.error };

        case ADD_PROCESSOR_SUCCESS:
            // Add the new processor to the existing data in the store
            processorStore.setProcessorData([...state.data, action.payload]);
            return { ...state, loading: false, data: [...state.data, action.payload], error: null };

        case ADD_PROCESSOR_FAILURE:
            processorStore.setError(action.error);
            return { ...state, loading: false, error: action.error };

        case DELETE_PROCESSOR_SUCCESS:
            processorStore.setProcessorData(
                state.data.filter((processor) => processor.id !== action.payload)
            );
            return {
                ...state,
                data: state.data.filter((processor) => processor.id !== action.payload),
            };

        case DELETE_PROCESSOR_FAILURE:
            processorStore.setError(action.error);
            return { ...state, error: action.error };

        default:
            return state;
    }
};

export default processorReducer;
