import { useEffect, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useStore } from "zustand";
import SkeletonWrapper from "../Wrappers/Skeleton/SkeletonWrapper";
import PopoverCardSection from "./PopoverCardSection";
import CountUp from "react-countup";
import updateRateStore from "@/components/common/updateRateStore"; // Import the store itself
import dashboardStore from "@/pages/Dashboard/dashboardStore";
import { BugIcon, CpuIcon } from "lucide-react";
import { toShortForm } from "@/lib/utils.js";

const LiveCountPopover = () => {
  const { categoryWiseCountsWithTotal, counts, loading } =
    useStore(dashboardStore);
  const { data, updateData } = useStore(updateRateStore);
  const [liveProcessorCount, setLiveProcessorCount] = useState(0);
  const [prevLiveProcessorCount, setPrevLiveProcessorCount] = useState(0);
  const [liveScraperCount, setLiveScraperCount] = useState(0);
  const [prevLiveScraperCount, setPrevLiveScraperCount] = useState(0);

  const [processorRate, setProcessorRate] = useState(0);
  const [scraperRate, setScraperRate] = useState(0);

  useEffect(() => {
    setPrevLiveProcessorCount(liveProcessorCount);
    setLiveProcessorCount(
      categoryWiseCountsWithTotal?.processors?.find(
        (item) => item.source === "live"
      )?.countsToday
    );

    setPrevLiveScraperCount(liveScraperCount);
    setLiveScraperCount(categoryWiseCountsWithTotal?.scrapers?.live?.stats);
    // Calculate rate for each processor
    categoryWiseCountsWithTotal?.processors?.forEach((item) => {
      let endCount = item?.countsToday;
      let storeKey = `processors_${item.source}`;

      // Use the custom hook to calculate the rate
      updateData(storeKey, endCount); // Update the store with the latest count and key
    });

    Object.keys(categoryWiseCountsWithTotal?.scrapers).forEach((item) => {
      let endCount = categoryWiseCountsWithTotal?.scrapers[item]?.stats;
      let storeKey = `scrapers_${item}`;

      updateData(storeKey, endCount);
    });
  }, [categoryWiseCountsWithTotal]);

  useEffect(() => {
    setProcessorRate(data?.processors_live?.current_rate?.toFixed(2) ?? 0);
    setScraperRate(data?.scrapers_live?.current_rate?.toFixed(2) ?? 0);
  }, [data]);

  const tabDataCount = [
    {
      value: "processors",
      component: (
        <div>
          {loading && counts.length === 0 ? (
            <SkeletonWrapper
              width={"100%"}
              height={"9rem"}
              count={"7"}
              className={"flex flex-wrap gap-6"}
            />
          ) : (
            <div className={"flex flex-wrap w-full gap-2"}>
              {categoryWiseCountsWithTotal?.processors?.map((item, index) => {
                if (item.source === "live") return null;
                return (
                  <PopoverCardSection
                    key={index}
                    title={item.source}
                    count={item.countsToday}
                    countsToday={item.countsToday}
                    countsYesterday={item.countsYesterday}
                    type="processors"
                  />
                );
              })}
            </div>
          )}
        </div>
      ),
    },
    {
      value: "scrapers",
      component: (
        <div>
          {loading && counts.length === 0 ? (
            <SkeletonWrapper
              width={"100%"}
              height={"9rem"}
              count={"5"}
              className={"flex flex-wrap gap-6"}
            />
          ) : (
            <div className={"flex flex-wrap w-full gap-2"}>
              {categoryWiseCountsWithTotal?.scrapers &&
                Object.keys(categoryWiseCountsWithTotal?.scrapers).map(
                  (item, index) => {
                    if (item === "live") return null;
                    return (
                      <PopoverCardSection
                        key={index}
                        title={item}
                        count={
                          categoryWiseCountsWithTotal.scrapers[item].stats ?? 0
                        }
                        countsToday={
                          categoryWiseCountsWithTotal.scrapers[item].stats ?? 0
                        }
                        countsYesterday={
                          categoryWiseCountsWithTotal.scrapers[item]
                            .yesterday_stats ?? 0
                        }
                        type="scrapers"
                      />
                    );
                  }
                )}
            </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <div className="fixed bottom-8 right-8">
      <Popover>
        <PopoverTrigger className="cursor-pointer bg-primary text-white rounded-md shadow-">
          <div className="w-[250px] px-4 py-2 flex flex-col gap-3">
            <div className={"flex flex-row gap-2 items-center"}>
              <BugIcon size={20} />
              <div
                className={"w-full flex flex-row justify-between items-center"}
              >
                <CountUp
                  start={prevLiveScraperCount}
                  end={liveScraperCount}
                  duration={10}
                  separator=","
                />
                <p className={"text-xs"}>{toShortForm(scraperRate)} /min</p>
              </div>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <CpuIcon size={20} />
              <div
                className={"w-full flex flex-row justify-between items-center"}
              >
                <CountUp
                  start={prevLiveProcessorCount}
                  end={liveProcessorCount}
                  duration={10}
                  separator=","
                />
                <p className={"text-xs"}>{toShortForm(processorRate)} /min</p>
              </div>
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent className="p-3 m-3 bg-white shadow-lg rounded-lg w-[450px]">
          <Tabs defaultValue={tabDataCount[0].value} className="h-full">
            <TabsList className="flex gap-10 mb-2">
              {tabDataCount.map((item, index) => (
                <TabsTrigger
                  key={index}
                  value={item.value}
                  className="w-full px-4"
                >
                  {item.value.charAt(0).toUpperCase() + item.value.slice(1)}
                </TabsTrigger>
              ))}
            </TabsList>

            {tabDataCount.map((item, index) => (
              <TabsContent key={index} value={item.value}>
                {item.component}
              </TabsContent>
            ))}
          </Tabs>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default LiveCountPopover;
