import { create } from "zustand";

const useProcessorStore = create((set) => ({
    processorData: [],
    loading: false,
    error: null,

    setProcessorData: (data) => set({ processorData: data, 
    loading: false,
    error: null }),
    setLoading: (loading) => set({ loading }),
    setError: (error) => set({ error, loading: false })
    
}));

export default useProcessorStore;
