import "./App.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Navbar from "@/components/Navbar/Navbar.jsx";
import { Route, Routes } from "react-router-dom";
import routes from "@/routes/routes";
import { useEffect, useMemo } from "react";
import { getDashboardCounts, getGraphData } from "@/redux/Dashboard/actions.js";
import { useDispatch } from "react-redux";
import LiveCountPopover from "./components/common/LiveCountPopover/LiveCountPopover";
import { getProcessorData } from "./redux/Processor/actions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import updateRateStore from "./components/common/updateRateStore";
import ProtectedRoute from "./routes/ProtectedRoute";

const Container = ({ children }) => {
  return (
    <div className={"flex justify-center py-6 w-full  px-20"}>{children}</div>
  );
};

function App() {
  const dispatch = useDispatch();
  const isAuth = updateRateStore((state) => state.isAuth);

  useEffect(() => {
    const handleUnlockShortcut = (e) => {
      if (e.ctrlKey && e.shiftKey && e.code === "KeyH") {
        updateRateStore.setState({ isAuth: true, isUnlocked: true });
        toast.success("Unlocked!");
      }
    };
    window.addEventListener("keydown", handleUnlockShortcut);

    return () => {
      window.removeEventListener("keydown", handleUnlockShortcut);
    };
  }, []);

  const iRoutes = useMemo(() => {
    return routes.map((route, index) => {
      const Component = route.component;
      const isProtected = ["/dashboard", "/gpu-fleet"].includes(route.path);
      return (
        <Route
          key={index}
          path={route.path}
          element={
            isProtected ? (
              <ProtectedRoute isAuth={isAuth}>
                <Container>
                  <Component />
                </Container>
              </ProtectedRoute>
            ) : (
              <Container>
                <Component />
              </Container>
            )
          }
        />
      );
    });
  }, [isAuth]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getDashboardCounts({ id: "processors" }));
    }, 3000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProcessorData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDashboardCounts({ id: "processors" }));
    dispatch(getGraphData());
  }, [dispatch]);

  // const saltRounds = 10;
  // const password = "HaE@37H_3#";

  // bcrypt.hash(password, saltRounds, function (err, hash) {
  //     if (err) throw err;
  //     console.log(hash); // Use this hash in your .env file
  // });

  return (
    <>
      <div className={"w-full flex justify-center h-[8vh] "}>
        <Navbar />
      </div>
      <div className={"h-[92vh]"}>
        <Routes>
          <>{iRoutes}</>
        </Routes>
      </div>

      {isAuth ? <LiveCountPopover /> : ""}

      <ToastContainer />
    </>
  );
}

export default App;
