export function CircularProgress({
  max = 100,
  min = 0,
  value = 0,
  gaugePrimaryColor = "green",
  gaugeSecondaryColor = "#EDEDED",
  className,
  icon,
}) {
  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const currentPercent = Math.round(((value - min) / (max - min)) * 100);
  const strokeDasharray = `${
    (currentPercent / 100) * circumference
  } ${circumference}`;

  return (
    <div
      className={`relative w-[46px] h-[46px] text-xs font-semibold ${className}`}
    >
      <svg
        className="w-full h-full transform -rotate-90"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="25"
          cy="25"
          r={radius}
          fill="none"
          stroke={gaugeSecondaryColor}
          strokeWidth="4"
        />
        <circle
          cx="25"
          cy="25"
          r={radius}
          fill="none"
          strokeLinecap="round"
          stroke={gaugePrimaryColor}
          strokeWidth="4"
          strokeDasharray={strokeDasharray}
          strokeDashoffset="0"
          className="transition-all duration-500 ease-linear"
        />
      </svg>

      <span className="absolute inset-0 flex items-center justify-center">
        {icon}
      </span>
    </div>
  );
}
